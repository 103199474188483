require('./bootstrap');
import Swiper, { Navigation, Autoplay } from 'swiper';

$(document).ready(function(){

    Swiper.use([Navigation, Autoplay]);

    $('.category-swiper').each(function( index ) {
          let numberOfPosts = $(this).find('.swiper-slide').length;

            let options = {
            // Optional parameters
            // direction: 'horizontal',
            // loop: true,

            // autoplay: {
            //     delay: 6000,
            //     disableOnInteraction: false
            // },

            // If we need pagination
            // pagination: {
            //   el: '.swiper-pagination',
            // },

            //Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next-' + $(this).data('category_id'),
              prevEl: '.swiper-button-prev-' + $(this).data('category_id'),
            },

            // // And if we need scrollbar
            // scrollbar: {
            //   el: '.swiper-scrollbar',
            // },

            slidesPerView: 1,
            spaceBetween: 10,
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                320: {
                slidesPerView: 2,
                spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                slidesPerView: 3,
                spaceBetween: 30
                },
                // when window width is >= 640px
                640: {
                slidesPerView: 5,
                spaceBetween: 40
                }
            }
        }

          if (numberOfPosts > 5) {
            options.loop = true;
            // options.autoplay = {
            //     delay: 6000,
            //     disableOnInteraction: false
            // };
            options.navigation = {
                nextEl: '.swiper-button-next-' + $(this).data('category_id'),
                prevEl: '.swiper-button-prev-' + $(this).data('category_id'),
            };
          }

        new Swiper('.swiper-' + $(this).data('category_id'), options);

    });

    $('.authLink').on('click', function(){
        let type = $(this).data('auth_type');
        let loginTab = $('#loginTab');
        let registerTab = $('#registerTab');

        if(type === 'login') loginTab.trigger("click");
        if(type === 'register') registerTab.trigger("click");
    })

    // $('#register-button').on('click', function(e){
    //     e.stopPropagation();
    //     e.preventDefault();
    //     let form = $('#registration-form');

    //     let validator = form.validate({ rules: {
    //         password: "required",
    //             password_confirmation: {
    //             equalTo: "#password"
    //         }
    //     },
    //     messages: {
    //         password: "Enter Password",
    //         password_confirmation: "Confirm Password needs to be the same as Password"
    //     }
    //     });

    //     if(validator.form()) {
    //         let name = form.find('input[name=name]').val();
    //         let surname = form.find('input[name=surname]').val();
    //         let email = form.find('input[name=email]').val();
    //         let church_name = form.find('input[name=church_name]').val();
    //         let password = form.find('input[name=password]').val();
    //         let password_confirmation = form.find('input[name=password_confirmation]').val();

    //         let data = {
    //             'name': name,
    //             'surname': surname,
    //             'email': email,
    //             'church_name': church_name,
    //             'password': password,
    //             'password_confirmation': password_confirmation,
    //         }

    //         $.ajax({
    //             data: data,
    //             url: '/register',
    //             type: 'POST',
    //             success: function(response) {
    //                 $('#authModal').modal('hide');
    //                 $('#inscription-confirmation').modal('show');
    //             },
    //             error: function(response) {

    //             }
    //         });
    //     }
    // })

    $('#go-to-login').on('click', function(){
        $('#inscription-confirmation').modal('hide');
        $('#authModal').modal('show');
        $('#loginTab').trigger("click");
    })

    $('.like-image').on('click', function(){

        $.ajax({
            url: '/post/' + $(this).data('post_id') + '/user/' + $(this).data('user_id') + '/like',
            type: 'POST',
            success: function(response) {
                let domain = document.domain;
                if(response){
                    let route = 'https://' + domain + '/themes/implication/assets/images/ic-heart-full.svg';
                    $('.like-image').attr('src', route);
                }else{
                    let route = 'https://' + domain + '/themes/implication/assets/images/ic-heart-empty.svg';
                    $('.like-image').attr('src', route);
                }
            },
            error: function(response) {

            }
        });
    })
});








